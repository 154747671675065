/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: null,
    name: "إعدادات عامة",
    tagColor: "warning",
    icon: "SettingsIcon",
    submenu: [
      {
        url: "/roles",
        name: "الأدوار",
        slug: "dashboard-ecommerce",
      },
      {
        url: "/users/dashboard",
        name: "مستخدمي لوحة التحكم",
        slug: "dashboard-ecommerce",
        permission: "get_users"
      }
    ]
  },
  {
    url: null,
    name: "المتجر الالكتروني",
    tagColor: "warning",
    icon: "ShoppingCartIcon",
    submenu: [
      {
        url: "/ecommerce/market",
        name: "المتاجر",
        slug: "dashboard-ecommerce",
        permission:"get_markets"
      },
      {
        url: '/ecommerce/stores',
        name: 'المحال',
        slug: 'ecommerce-stores',
        permission:"show_stores"
      },
      {
        url: '/ecommerce/categories',
        name: 'اصناف المنتجات',
        slug: 'ecommerce-categories',
        role: 'store_admin',
        permission: "get_categories"
      },
      {
        url: '/ecommerce/variant',
        name: 'الخصائص',
        slug: 'ecommerce-products',
        permission: 'get_variantKeys'
      },
      {
        url: "/tags",
        name: "التاغات",
        slug: "dashboard-ecommerce",
        permission: "get_subProductTags"
      },
      {
        url: '/ecommerce/products',
        name: 'المنتجات',
        slug: 'ecommerce-products',
        role: 'store_admin',
        permission:"get_products"
      },
      {
        url: "/sliders",
        name: "سلايدرات",
        slug: "dashboard-ecommerce",
        permission:'get_sliders'
      },
      {
        url: "/ecommerce/packageSizes",
        name: "التغليف",
        slug: "dashboard-ecommerce",
        permission:'get_package_sizes'
      },
      {
        url: "/ecommerce/users",
        name: "حسابات الزبائن",
        slug: "dashboard-ecommerce",
        permission: "get_users"
      },
      {
        url: null,
        name: 'الطلبات',
        role: 'store_admin',
        tagColor: "warning",
        permission:'get_orders',
        submenu: [
          {
            url: "/ecommerce/personalOrders",
            name: "شخصي",
            slug: "ecommerce-orders",
            permission:"get_personalOrders"
          },
          {
            url: "/ecommerce/friendGiftOrders",
            name: "هدية لصديق",
            slug: "ecommerce-orders",
            permission:'get_friendGift'
          },
          {
            url: "/ecommerce/orphanGiftOrders",
            name: "هدية ليتيم",
            slug: "ecommerce-orders",
            permission:'get_orphanOrders'
          }
        ]
      },
    ]
  }
];
