<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ user.email }}</p>
      <small>{{ user.fullName }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="user.image_url" :src="user.image_url"  alt="user-img" width="40"
             height="40" class="rounded-full shadow-md cursor-pointer block"/>
        <img v-else src="@/assets/images/portrait/small/person.jpg" alt="user-img" width="40"
             height="40" class="rounded-full shadow-md cursor-pointer block"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="showProfileInfo(user)">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">الملف الشخصي</span>
          </li>
          <vs-divider class="m-1"/>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="checkNotification">
            <feather-icon icon="NotificationIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">التأكد من حالة الإشعارات</span>
          </li>
          <vs-divider class="m-1"/>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">تسجيل الخروج</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
    <vs-popup title="الملف الشخصي" :active.sync="popupActive">
      <vs-progress v-if="isLoading" indeterminate color="primary"></vs-progress>
      <ValidationObserver v-slot="{handleSubmit}" ref="validationObserver">
        <form>
          <div class="vx-row">
            <div class="vx-col  w-full text-center">
              <h4 class="my-5">الصورة الشخصية</h4>
              <image-uploader @fileUploaded="fileUploaded"
                              :imagePreview="imagePreview"
                              :isImageRequired="imageRequired"
                              ref="imageUploader"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الاسم</label>
                <vs-input  placeholder="اسم المستخدم" v-model="user.first_name" class="w-full"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الكنية</label>
                <vs-input placeholder="الكنية" v-model="user.last_name" class="w-full"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <label>الجنس</label>
                <v-select
                        :options="genderOptions" :reduce="name => name.value" label="name"
                        v-model="user.gender"/>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button :disabled="isLoading" ycolor="success" type="filled" @click.prevent="handleSubmit(onSubmit)">
                تعديل
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>

</template>

<script>
  import {vapidKey} from "@/firebase";
  import toasted from "@/app/shared/utilities/toasted";
  import imageUploader from '@/app/shared/shared-components/ImageUploader';
  import vSelect from 'vue-select';
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import utilities from "@/app/shared/utilities/index"
  const userRepo = SharedRepositoryFactory.get('userRepository');
  export default {
    components: {
      imageUploader,
      vSelect
    },
    data() {
      return {
        notificationPermission: Notification.permission,
        user: {
          id: '',
          first_name: '',
          last_name: '',
          email:'',
          image_url: '',
          password:'',
          password_confirmation:'',
          gender:''
        },
        genderOptions:[
          {
            name:'ذكر',
            value:'Male'
          },
          {
            name:'أنثى',
            value:'Female'
          }
        ],
        imageRequired: true,
        imagePreview: null,
        isLoading: false,
        popupActive: false
      }
    },
    created() {
      this.$store.dispatch('getUserInfo').then(() => {
        this.user = JSON.parse(localStorage.getItem('vuex')).sharedModule.user
      })
    },
    methods: {
      logout() {
        this.$store.dispatch('logout', this.user.deviceToken)
      },
      checkNotification() {
        if (this.user.deviceToken === null) {
          if (this.notificationPermission === 'denied')
            toasted.failed('يجب السماح بتلقي الإشعارات ثم إعادة التأكد');
          else if (this.notificationPermission !== 'default') {
            toasted.success('جار السماح بتلقي الإشعارات');
            this.$messaging.getToken({vapidKey})
                    .then((currentFcmToken) => {
                      if (currentFcmToken) {
                        this.$store.dispatch('addDeviceToken', currentFcmToken).then(() => {
                          this.user = JSON.parse(localStorage.getItem('vuex')).sharedModule.user
                        });
                      }
                    })
                    .catch(error => console.log(error));
          } else {
            this.user = JSON.parse(localStorage.getItem('vuex')).sharedModule.user;
            this.checkNotification()
          }
        } else if (this.notificationPermission !== 'denied')
          toasted.success('تلقي الإشعارات مسموح');
        else
          toasted.success('تلقي الإشعارات مسموح , للمنع يجب تسجيل الخروج');
      },
      showProfileInfo(user) {
        this.imagePreview = user.image_url;
        this.openPopup();
      },
      fileUploaded(file) {
        this.user.image_url = file;
      },
      openPopup() {
        this.$refs.validationObserver.reset();
        this.popupActive = true;
      },
      onSubmit() {
        this.isLoading = true;
        if (typeof this.user.image_url == "string")
          delete this.user.image_url;
        this.user= utilities.transformToFormData(this.user);
          userRepo.updateProfileInfo(this.user).then(() => {
            this.$store.dispatch('getUserInfo').then(() => {
              this.user = JSON.parse(localStorage.getItem('vuex')).sharedModule.user
            });
            this.isLoading = false;
            this.popupActive = false;
          });
        }
    }
  }
</script>
